export const API_URL = 'https://devorder.crowndigital.io/admin/web/v1/';
export const API_URL2 = 'https://devorder.crowndigital.io/web/';
//export const LOCAL_PAYMENT_API_URL = "https://192.168.10.11";
export const LOCAL_PAYMENT_API_URL = "http://localhost";
export const LOCAL_PAYMENT_PORT = "5000";
export const SUICA_SERVER = "7700";
export const PRINT_TIMEOUT = 20000;
export const telemetryTimeout = 5000;
export const setIntervalTimeOut =1000;
export const QRcodeTimeout = 10000;
export const cart_time = 30;
export const payment_timer = 30;
export const modalTimeout = 6000;
export const PRINT_API_URL = "http://localhost:3333/?purl=";
export const KIOSK_AUTO_LOGIN ="https://gitex.crowndigital.io/kioskautologin/46e56162715d3539f0cce6ddb8cf1326/d16cfc87c06399f07ca952ac271ff0e7";
export const page_redirection_time = 30;
export const apitimeout = 29000;
export const cancel_timeout = 10000;
export const enable_test_buttons = false;
export const payment_failed_modal = 30;
export const settlement_timer = 30;
export const terminalCheck = 10000;
export const terminal_check_enable = true;
export const menu_items_timer = 20000;
export const images_display_timer = 15000;
export const DOMAIN_URL = "https://gitex.crowndigital.io";
export const qr_page_redirection_time = 65;
export const qr_page_retry_modal_time = 15;
export const apiSigningKey = '3JZqRZ6RvUOEBT92nmNLyA';
